import { FormikInput, FormikSelect, Heading2 } from '@mvr/ui'
import map from 'lodash/map'

import { paths } from 'api'
import { useFetch } from 'hooks'

import { CardHeading, FormContainer, InputRow } from '../../../Form.styles'

export const VehicleDetails = () => {
  const { useGetQuery } = useFetch()
  const makesQuery = useGetQuery<any>([paths.makes()], paths.makes())
  const { data: makes } = makesQuery.data?.data || {}
  const makesOptions = map(makes, (make: any) => ({ value: make?.uuid, label: make?.name }))

  return (
    <FormContainer>
      <CardHeading>
        <Heading2>Vehicle Details</Heading2>
      </CardHeading>

      <InputRow>
        <FormikSelect name='make_uuid' placeholder='Make' options={makesOptions} searchable required />
        <FormikInput
          maxLength={17}
          name='vin'
          placeholder='VIN / Chassis No.'
          autoComplete='off'
          hint='The unique vehicle identification number allocated to a vehicle when the vehicle is manufactured'
          required
        />
      </InputRow>
    </FormContainer>
  )
}
