export const Error = {
  required: '{fieldName} is required. Please fill out this field.',
  phoneNumber: '{fieldName} should be in the format of 0XXX XXX XXX or +256 XXX XXX XXX. Please check and try again.',
  model:
    '{fieldName} can only include letters, numbers, spaces, hyphens, slash, and ampersand. Please check and try again.',
  name: '{fieldName} can only include letters, numbers, apostrophes, hyphens, spaces, dots and comas. Please check and try again.',
  text: '{fieldName} can only include letters, apostrophes, hyphens, spaces, dots and comas. Please check and try again.',
  charsNumbers: '{fieldName} can only include letters and numbers. Please check and try again.',
  charsNumbersSpaces: '{fieldName} can only include letters, numbers and spaces. Please check and try again.',
  minCharacters: '{fieldName} length should be minimum {value} characters.',
  maxCharacters: '{fieldName} length should be {value} characters maximum.',
  length: 'The number of characters in the {fieldName} must be equal to {value}',
  range: '{fieldName} could be only in range {min}-{max}. Please check and try again.',
  minDigits: 'Minimum {value} digits',
  exactlyDigits: 'The {fieldName} entered should be exactly {value} digits long',
  pinFormat: 'The {fieldName} must be 4 digits',
  dateInPast: 'The date must be in the past and not in the future',
  dateInFuture: 'The date must be in the future and not in the past',
  dateMin: 'The date must be greater than or equal to {value}',
  numbersOnly: '{fieldName} should contain the numbers only',
  email: 'Please enter a valid email address (for example, name@example.com)',
  file: 'The file is not valid',
  rangeNumber: 'The {fieldName} could be only in range {min}-{max}. Please check and try again.',
  maxFileSize: '{fieldName} that you’re trying to upload is too large. Please upload file up to {size}MB.',
  minYear: 'The {fieldName} should be starting from {min}',
  tyreSizeFormat: '{fieldName} size should be in following format: (2-3 numeric)/(2 numeric)R(2 numeric).',
  uniqueness: 'This {fieldName} already exists. Please enter another one.',
  notEqual: 'The {fieldName} does not match.',
  // prettier-ignore
  vin: 'The {fieldName} can only include numbers, hyphens, and letters except \'I\', \'O\' and \'Q\'. Please check and try again.',
}

export const en = {
  Error,
}

export default en
